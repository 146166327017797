<template>
  <div class="travelCateList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>广告线路列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>分类名称:</span>
      <el-input v-model="list.title" placeholder="请输入分类名称"></el-input>
      <el-button type="primary" @click="getTravelCateList()">查找</el-button>
      <el-button
        type="primary"
        @click="$router.push(`/addTravelCateAd?id=${$route.query.cateId}`)"
        >添加</el-button
      >
    </div>
    <el-table :data="tableData" style="width: 100%" border>
      <el-table-column
        align="center"
        prop="advId"
        label="分类ID"
        width="width"
      ></el-table-column>
      <el-table-column align="center" prop="detp" label="排序">
        <template slot-scope="{ row }">
          <el-input
            class="input-new-tag"
            v-if="row.inputVisible"
            v-model="row.dept"
            ref="saveTagInput"
            size="small"
            @input="onInput"
            @keyup.enter.native="handleInputConfirm(row)"
            @blur="handleInputConfirm(row)"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="onChangeSort(row)"
            >{{ row.dept }}</el-button
          >
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        prop="cateName"
        label="所属分类"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="advType"
        label="所在页面"
        width="width"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="title"
        label="广告标题"
        width="width"
      ></el-table-column>
      <el-table-column align="center" label="广告图" width="width">
        <template slot-scope="{ row }">
          <el-image
            style="width: 70px; height: 70px"
            :src="row.imgUrl"
            :preview-src-list="[row.cateImg]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="skipType" label="跳转页面">
      </el-table-column>
      <el-table-column align="addTime" prop="addTime" label="添加时间">
      </el-table-column>
      <el-table-column align="center" prop="status" label="状态值">
      </el-table-column>
      <el-table-column align="center" prop="strStatus" label="状态">
      </el-table-column>
      <el-table-column align="center" label="操作" width="300">
        <template slot-scope="{ row }">
          <el-button type="primary" size="mini" @click="onEdit(row)"
            >编辑</el-button
          >
          <el-button type="primary" size="mini" @click="onDel(row)"
            >删除</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="onShowOrHide(row.strStatus, row.advId)"
            >{{ row.strStatus == "显示" ? "隐藏" : "显示" }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { adList, delAd, adDept, showOrHide } from "../../api/travel";
import { sendFile } from "../../api/sendFile";
import sendImage from "../../components/sendImage.vue";
export default {
  components: { sendImage },
  name: "travelCateList",
  data() {
    return {
      addDialogVisible: false,
      editDialogVisible: false,
      addForm: {
        fatherId: 0,
        cateId: 0,
        cateName: "",
        cateImg: "",
        showImg: "",
        showType: "",
        isSearch: "",
        isAdd: "",
      },
      editForm: {
        cateName: "",
        fatherId: Number(this.id),
        cateId: null,
        cateImg: "",
        showImg: "",
        showType: "",
        isSearch: "",
        isAdd: "",
      },
      list: {
        currentPage: 1,
        pageSize: 5,
        title: "",
        cateId: "",
        advType: "",
      },
      tableData: [],
      pagination: {},
    };
  },
  created() {
    this.list.cateId = this.$route.query.cateId;
    this.getTravelCateList();
  },
  methods: {
    onShowOrHide(strStatus, id) {
      let status;
      if (strStatus == "显示") {
        status = 2;
      } else {
        status = 1;
      }
      this.$confirm("是否操作这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const { data } = await showOrHide({
          ids: id,
          status,
        });
        if (data.code == 0) {
          this.$message({
            type: "success",
            message: data.msg,
          });
          this.getTravelCateList();
        } else {
          this.$message({
            type: "error",
            message: data.msg,
          });
        }
      });
    },
    onChangeSort(row) {
      console.log(row);
      row.inputVisible = true;
    },
    onInput(value) {
      var reg = /^[0-9]*[1-9][0-9]*$/;
      var reg2 = /^-[0-9]*[1-9][0-9]*$/;
      if (!reg.test(value) && !reg2.test(value)) {
        return this.$message({
          type: "warning",
          message: "请输入整数!",
        });
      }
    },
    async handleInputConfirm(row) {
      const { data } = await adDept({
        advId: row.advId,
        dept: Number(row.dept),
      });
      console.log(data, "paixun");
      row.inputVisible = false;
    },
    changeStatus(cateId, type) {
      this.$confirm("是否操作这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await changeStatus({
            cateId: cateId,
            status: type,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getTravelCateList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    async getTravelCateList() {
      const { data } = await adList(this.list);
      data.list.map((item) => {
        item.inputVisible = false;
      });
      this.tableData = data.list;
      this.pagination = data.pagination;
    },
    async onAddOk() {
      console.log("添加OK");
      if (this.addForm.cateName === "" || this.addForm.cateImg === "") {
        this.$message({
          message: "请把数据填写完整",
          type: "warning",
        });
        return;
      }
      const { data } = await addTravelCate(this.addForm);
      if (data.code == 0) {
        this.$message({
          message: "添加成功",
          type: "success",
        });
        this.addForm.cateName = "";
        this.addForm.cateImg = "";
      }
      this.addDialogVisible = false;
      this.getTravelCateList();
    },
    //删除
    onDel(row) {
      console.log(row, "alskdjaslkdjaslkdj");
      this.$confirm("是否删除这条分类?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await delAd({
            ids: row.advId,
          });
          console.log(data);
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getTravelCateList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onInput(value) {
      var reg = /^[0-9]*[1-9][0-9]*$/;
      var reg2 = /^-[0-9]*[1-9][0-9]*$/;
      if (!reg.test(value) && !reg2.test(value)) {
        return this.$message({
          type: "warning",
          message: "请输入整数!",
        });
      }
    },
    onEdit(row) {
      console.log("asldkj");
      this.$router.push(`/addTravelCateAd?advId=${row.advId}`);
    },
    async onEditOk() {
      console.log("编辑分类");
      if (
        this.editForm.cateName === "" ||
        this.editForm.cateImg === "" ||
        this.editForm.cateImg === null
      ) {
        return this.$message.warning("请把数据填写完整");
      }
      const { data } = await editTravelCate(this.editForm);

      if (data.code == 0) {
        this.editDialogVisible = false;
        this.getTravelCateList();
        this.$message({
          type: "success",
          message: "编辑成功",
        });
        console.log(this.editForm, "formedit");
      } else {
        this.$message({
          type: "error",
          message: "编辑失败",
        });
      }
    },
    addImageStr(image, type) {
      console.log(image, type);
      if (type === 1) {
        this.addForm.cateImg = image;
        this.editForm.cateImg = image;
      } else if (type === 2) {
        this.editForm.cateImg = image;
      } else if (type === 3) {
        console.log("类型是3");
        this.editForm.showImg = image;
        this.addForm.showImg = image;
        console.log(this.editForm.showImg, "img");
        console.log(this.editForm, "editForm");
      }
    },

    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getTravelCateList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getTravelCateList();
    },
  },
};
</script>
<style lang="less" scoped>
.travelCateList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    .el-input {
      width: 200px;
      margin: 0 40px 0 20px;
    }
  }
  .el-table {
    margin-top: 30px;
    .el-table__body-wrapper {
      table {
        td {
          .cell {
            img {
              width: 80px;
              height: 80px;
            }
          }
        }
      }
    }
  }
  /deep/.el-dialog {
    .el-form-item__content {
      // display: flex;
      .img {
        position: relative;
        height: 100px;
        width: 100px;
        margin: 20px 20px;
        img {
          height: 100px;
          width: 100px;
          border: 1px solid #c0ccda;
        }
        div {
          position: absolute;
          top: 0;
          right: 2px;
          height: 10px;
          width: 10px;
          z-index: 100;
        }
      }
      .addImg {
        display: inline-block;
        width: 100px;
        height: 100px;
        border: 1px dashed #c0ccda;
        text-align: center;
        line-height: 100px;
        cursor: pointer;
        margin-top: 20px;
        i {
          width: 50px;
          height: 50px;
          font-size: 20px;
          color: #c0ccda;
        }
      }
    }
  }
  .el-pagination {
    margin-top: 30px;
    float: right;
  }
}
</style>